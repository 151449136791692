// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
const attributeIsSecondaryIndex = (attr) => {
    return (attr.type === 'key' &&
        // presence of `name` property distinguishes GSI from primary index
        attr.properties?.name &&
        attr.properties?.queryField &&
        attr.properties?.fields.length > 0);
};
export const getSecondaryIndexesFromSchemaModel = (model) => {
    const idxs = model.attributes
        ?.filter(attributeIsSecondaryIndex)
        .map((attr) => {
        const queryField = attr.properties.queryField;
        const [pk, ...sk] = attr.properties.fields;
        return {
            queryField,
            pk,
            sk,
        };
    });
    return idxs || [];
};
