// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { validationErrorMap, } from '../types/validation';
import { StorageError } from '../StorageError';
export function assertValidationError(assertion, name) {
    const { message, recoverySuggestion } = validationErrorMap[name];
    if (!assertion) {
        throw new StorageError({ name, message, recoverySuggestion });
    }
}
